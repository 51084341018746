.App {
  text-align: center;
  background-color: #061431;
}

.intro {
  background-color: black;
  transition: background-color 0.5s ease;
}

.projects {
  background-color: #2f009c;
  transition: background-color 0.5s ease;
}

.skills{
  background-color: #200077;
  transition: background-color 0.5s ease;
}

.background {
  background-color: #19013f;
  transition: background-color 0.5s ease;
}

.contact {
  background-color: #3d04c2;
  transition: background-color 0.5s ease;
}
